'use client';

import React, { CSSProperties, MouseEventHandler, SVGProps } from 'react';

const Icon = React.memo(function Icon(props: IconProps): JSX.Element {
  const { component: IconComponent, className, onClick, viewBox, style, width, height } = props;

  const getComponentProps: GetComponentPropsType = () => {
    const res: ReturnType<GetComponentPropsType> = {
      className,
      onClick,
      style,
    };

    if (typeof width === 'number' && width >= 0) {
      res.width = width;
    }

    if (typeof height === 'number' && height >= 0) {
      res.height = height;
    }

    if (viewBox) {
      res.viewBox = viewBox;
    }

    return res;
  };

  return <IconComponent {...getComponentProps()} />;
});

export { Icon };

interface IconProps {
  component: React.FC<SVGProps<SVGElement>>;
  className?: string;
  onClick?: MouseEventHandler<SVGElement>;
  viewBox?: `${number} ${number} ${number} ${number}`;
  style?: CSSProperties;
  width?: number;
  height?: number;
}

type GetComponentPropsType = () => {
  className: IconProps['className'];
  onClick: IconProps['onClick'];
  style: IconProps['style'];
  viewBox?: `${number} ${number} ${number} ${number}` | undefined;
  width?: number;
  height?: number;
};
