import { Metadata } from 'next';
import { LANGS_LIST, availablePathsForLocalizationArray } from '@/shared/constants';

function formatDataSize(dataSize: string | number) {
  return +dataSize >= 1000 ? `${+dataSize / 1000} GB` : `${dataSize} MB`;
}

function scrollToId(id: string, offsetY = 0) {
  if (typeof window === 'undefined') return;
  const el = document.getElementById(id);
  if (el) {
    window.scrollTo({
      top: el.getBoundingClientRect().top + window.scrollY - offsetY,
      behavior: 'smooth',
    });
  }
}

function getCurrencySymbol(currency: string) {
  return (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

function getErrorMessage(error: any): string {
  if (!error) return '';

  if (typeof error === 'string') return error;

  if (error.details) return error.details;

  if (error.errors?.length) {
    return Object.values(error.errors)
      .map((err: any) => err?.message)
      .join(' ');
  }

  if (error.message) {
    if (error.message.includes('Firebase')) {
      return error.message.split('/')[1].replaceAll(/[(\-)]/g, ' ');
    }
    return error.message;
  }

  if (error.errorMessage) return error.errorMessage;

  if (typeof error === 'object') {
    return Object.values(error).join(' ');
  }

  return 'Unknown error.';
}

function setCookie(name: string, value: string, days: number) {
  if (typeof window === 'undefined') return;
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; domain=.esimplus.me; path=/`;
}

function getCookie(name: string): string | null {
  if (typeof window === 'undefined') return null;

  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    while (c.startsWith(' ')) c = c.substring(1, c.length);
    if (c.startsWith(nameEQ)) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name: string) {
  document.cookie = `${name}=; domain=.esimplus.me; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

const getLocalePrefix = (locale: string) => (locale.startsWith('en') ? '' : `/${locale.slice(0, 2)}`);

function generateMeta({
  language,
  supportedLangs = LANGS_LIST,
  pathname,
  title,
  description,
}: {
  title: string;
  description: string;
  language: string;
  supportedLangs?: string[];
  pathname: string;
}): Metadata {
  const baseUrl = trimTrailingSlash(process.env.NEXT_PUBLIC_BASE_URL ?? 'https://esimplus.me/');

  const alternateLinksLanguagesDict = supportedLangs.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.toLocaleLowerCase()]: `${baseUrl}${getLocalePrefix(curr)}${pathname}`,
    }),
    {} as Record<string, string>
  );

  return {
    viewport: 'width=device-width, initial-scale=1',
    title,
    description,
    alternates: {
      canonical: `${baseUrl}${getLocalePrefix(language)}${pathname}`,
      ...(supportedLangs.length > 1 && {
        languages: {
          ...alternateLinksLanguagesDict,
          'x-default': `${baseUrl}${getLocalePrefix('en')}${pathname}`,
        },
      }),
    },
    openGraph: {
      locale: language,
      // @ts-ignore
      type: 'website',
      url: `${baseUrl}${getLocalePrefix(language)}${pathname}`,
      site_name: 'ESIM Plus',
      images: [
        {
          url: `${baseUrl}/assets/logo.png`,
          width: 112,
          height: 93,
        },
      ],
      title,
      description,
      'twitter:card': 'summary',
      'twitter:title': title,
      twitter: description,
      'twitter:image': '/assets/logo.png',
    },
  };
}

function formatPathToReadableEventName(path: string) {
  const questionMarkIndex = path.indexOf('?');
  const hasQuery = questionMarkIndex !== -1;
  return path.slice(1, hasQuery ? questionMarkIndex : undefined).replaceAll('/', '-') || 'home';
}

/**
 * Generates a translation key based on a base key and an optional country ISO code.
 *
 * The function concatenates the base key with an underscore and the country ISO code if provided.
 * If the ISO code is not provided, the base key is returned as is.
 *
 * @example
 * // returns 'some_key_CA'
 * buildTranslationKeyByIso('some_key', 'CA');
 *
 * @example
 * // returns 'some_key'
 * buildTranslationKeyByIso('some_key');
 */
function buildTranslationKeyByIso(key: string, iso?: string) {
  return `${key}${iso ? `.${iso}` : '.default'}`;
}

function trimTrailingSlash(str: string): string {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}

function replaceBaseURL(url: string, newBaseURL: string): string {
  // Extract the path from the original URL
  const urlObject = new URL(url);
  const path = urlObject.pathname;

  // Construct the new URL
  return new URL(path, newBaseURL).toString();
}

function detectLocale(pathname: string): string {
  // Split the pathname on '/'
  const segments = pathname.split('/');

  // Check if the first segment is a valid locale
  if (segments.length > 1 && LANGS_LIST.includes(segments[1])) {
    return segments[1]; // Return the locale
  }

  return 'en'; // No locale found, return null or default locale
}

function getEsimplusLink(locale: string, pathname: string) {
  const localePrefix = !availablePathsForLocalizationArray.includes(pathname) || locale === 'en' ? '' : `/${locale}`;
  return `${process.env.NEXT_PUBLIC_ESIMPLUS_URL}${localePrefix}${pathname}`;
}

function isIOS() {
  return (
    typeof window !== 'undefined' &&
    typeof window.navigator !== 'undefined' &&
    (window.navigator.platform.indexOf('Mac') === 0 ||
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        window.navigator.platform
      ) ||
      (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document))
  );
}

export {
  getEsimplusLink,
  isIOS,
  detectLocale,
  replaceBaseURL,
  trimTrailingSlash,
  buildTranslationKeyByIso,
  getLocalePrefix,
  formatPathToReadableEventName,
  formatDataSize,
  scrollToId,
  getCurrencySymbol,
  getErrorMessage,
  getCookie,
  setCookie,
  eraseCookie,
  generateMeta,
};
