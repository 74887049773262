import { countries } from 'country-cities';

export enum SectionIDS {
  SearchYourDestination = 'search_your_destination_section',
  InfoSection = 'info_section'
}

export enum CookiesNames {
  Consent = 'cookie-consent',
  SDUser = 'sd-user',
}

export const LANGS_LIST = ['ru', 'en', 'pl', 'de', 'es', 'pt', 'it', 'fr'];

export const BASE_STORAGE_URL = 'https://static.esimplus.net/storage/plans/';

export const ONE_LINK = 'https://esimplus.onelink.me/WxwP/e8qgk4iq';

export const SUPPORT_LINK = 'support.esim@appvillis.com';

export const DEFAULT_SELECTED_DATA_SIZE = 1000;

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const COUNTRY_LIST = countries.all();

export enum AvailablePathsForLocalization {
  Home = '/',
  NotFound = '/404',
  AboutUs = '/about-us',
  News = '/news',
  ContactUs = '/contact-us',
  Faq = '/faq',
  VirtualPhoneNumber = '/virtual-phone-number',
  RandomNumberGenerator = '/random-phone-number-generator',
  EsimSupportedDevices = '/esim-supported-devices',
}

export const availablePathsForLocalizationArray = Object.values(AvailablePathsForLocalization) as string[];

export enum PaymentProviders {
  Ecommpay = 'ecommpay',
  Webpay = 'webpay',
  Thedex = 'thedex',
}

export const PURCHASED_PRODUCT_TYPE_QUERY_KEY = 'purchased_product_type'

export enum ProductType {
  MOBILE_DATA = 'mobile-data',
}