export { default as FacebookIcon } from './facebook.svg';
export { default as TelegramIcon } from './telegram.svg';
export { default as LnIcon } from './ln.svg';
export { default as WhatWeAcceptIcon } from './what-we-accept.svg';
export { default as AppstoreIcon } from './appstore.svg';
export { default as AppstoreBlackIcon } from './appstore-black.svg';
export { default as ArrowRightBlackIcon } from './arrow-right-black.svg';
export { default as GPlayIcon } from './gplay.svg';
export { default as GPlayBlackIcon } from './gplay-black.svg';
export { default as MoonIcon } from './moon.svg';
export { default as SunIcon } from './sun.svg';
export { default as XMarkIcon } from './xmark.svg';
export { default as CardIcon } from './card.svg';
export { default as GlobeIcon } from './globe.svg';
export { default as CheckIcon } from './check.svg';
export { default as OrnamentIcon } from './ornament.svg';
export { default as BestPriceIcon } from './best-price.svg';
export { default as ArrowDown } from './arrow-down.svg';
export { default as StarIcon } from './star.svg';
export { default as RightUpArrow } from './right-up-arrow.svg';
export { default as TinderIcon } from './tinder.svg'
export { default as TiktokIcon } from './tiktok.svg'
export { default as WhatsAppIcon } from './whats-app.svg'
export { default as BinanceIcon } from './binance.svg'
export { default as USDTIcon } from './usdt.svg';
export { default as CheckLiteIcon } from './check-lite.svg';
